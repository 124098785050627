import { SkyKickUserProvider } from "@skykick/platform-identity-auth-auth0-angular";

export const environment = {
    production: false,
    sewrSystemDomain: 'https://sk-dev0-routing.skykick.com',
    userProvider: SkyKickUserProvider,
    appInsightsInstrumentationKey: "fe3986f4-77ec-4d0a-bf3c-15c1b9da080f",
    apis: {
        acceptJS: 'https://jstest.authorize.net/v1/Accept.js',
        authenticationApimSuffix: 'authenticationprovider',
        accountingApimSuffix: 'accountingservice',
    },
    securityManagerLicensingFeatureActive: true,
    dataDogTracing: {
        enabled: true,
        serviceName: 'skykick.web.portalv2',
        clientToken: 'pub8e89946113140972eb485a0207728366',
        applicationId: '3c999f32-a85d-4205-a679-1e35e65afc39',
        site: 'datadoghq.com',
        apmTracingEnabled: true,
        defaultPrivacyView: 'mask-user-input',
        sessionSampleRate: 50,
        sessionReplaySampleRate: 25,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        environment: 'dev',
        version: '2.0.0'
    }
};
